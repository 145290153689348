<template>
    <v-container fluid>
        <v-data-table :headers="headers" :items="items" :items-per-page="10" item-key="id" sort-by="name" calculate-widths
            class="elevation-4" :loading="loading_status" loader-height="10" loading-text="Cargando ...">
            <template #top>
                <v-toolbar flat>
                    <h3>Movimientos de inventario</h3>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-row class="pa-2">
                    <v-col cols="12" md="3">
                        <v-text-field v-model="dateFrom" label="Desde" hide-details="auto" required dense outlined
                            type="date" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="dateTo" label="Hasta" hide-details="auto" required dense outlined
                            type="date" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn color="success" @click="get_report">Buscar</v-btn>
                    </v-col>
                </v-row>
            </template>
             <template v-slot:[`item.categoria`]="{ item }">
                <span>{{ item.categoria + " / " + item.grupo  }}</span>
            </template>
            <template v-slot:[`item.brand`]="{ item }">
                <span>{{ item.brand + "-" + item.refe + "-" + item.color_name + "-" + item.talla }}</span>
            </template>
            <template v-slot:[`item.costo`]="{ item }">
                <span>{{ "$" + parseFloat(item.costo).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
                <span>{{ "$" + parseFloat(item.price).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.tcost`]="{ item }">
                <span>{{ "$" + parseFloat(item.tcost).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.tprice`]="{ item }">
                <span>{{ "$" + parseFloat(item.tprice).toLocaleString(2) }}</span>
            </template>
            <template slot="body.append">
                <tr>
                    <th>Total</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="text-right ">{{ sumTable('items', 'quantity') }}</th>
                    <th></th>
                    <th class="text-right ">{{ "$" + sumTable('items', 'tcost') }}</th>
                    <th></th>
                    <th class="text-right ">{{ "$" + sumTable('items', 'tprice') }}</th>
                </tr>
            </template>

        </v-data-table>
    </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";

export default {
    components: {},
    data() {
        return {
            errors: [],
            valid: false,
            loading_status: false,
            f_required: [(v) => !!v || "Requerido"],
            f_email: [
                (v) => !!v || "Correo valido",
                (v) => /.+@.+/.test(v) || "Correo no valido",
            ],
            imageUrl: null,
            dialog: false,
            headers: [
                {
                    text: "Fecha",
                    align: "start",
                    sortable: true,
                    value: "order_date",
                    dataType: "text",
                },
                {
                    text: "Documento",
                    align: "start",
                    sortable: true,
                    value: "order_number",
                    dataType: "text",
                },
                {
                    text: "Categoría",
                    align: "start",
                    sortable: true,
                    value: "categoria",
                    dataType: "text",
                },
                {
                    text: "Descripción",
                    align: "start",
                    sortable: true,
                    value: "brand",
                    dataType: "text",
                },
                {
                    text: "Cantidad",
                    align: "end",
                    sortable: true,
                    value: "quantity",
                    dataType: "number",
                },
                {
                    text: "Costo Uni",
                    align: "end",
                    sortable: true,
                    value: "costo",
                    dataType: "number",
                },
                {
                    text: "Precio Uni",
                    align: "end",
                    sortable: true,
                    value: "price",
                    dataType: "number",
                },
                {
                    text: "Costo",
                    align: "end",
                    sortable: true,
                    value: "tcost",
                    dataType: "number",
                },
                {
                    text: "Precio",
                    align: "end",
                    sortable: true,
                    value: "tprice",
                    dataType: "number",
                },

            ],
            items: [],
            dateFrom: getdays_ago(-30),
            dateTo: getToday(),
        };
    },
    mounted() {
        this.get_report();
    },
    methods: {
        sumTable(table, key) {
            return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
        },
        get_report() {
            this.loading_status = true;
            var qry = {
                store: window.store.store_id,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                events: "'OP','OC','AJ','CP','EI','SI','GI','GS','PS','PE','DV','CD','TB'",
            };

            webserver("get_events", qry, (data) => {
                console.log(data);
                data.forEach((itm) => {
                    itm.quantity = parseFloat(itm.quantity);
                    itm.tcost = parseFloat(itm.quantity) * parseFloat(itm.costo);
                    itm.tprice = parseFloat(itm.quantity) * parseFloat(itm.price);
                });
                this.loading_status = false;
                this.items = data;
            });
        },
    },
};
</script>

<style>
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"item-key":"id","sort-by":"name","calculate-widths":"","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('h3',[_vm._v("Movimientos de inventario")]),_c('v-spacer')],1),_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Desde","hide-details":"auto","required":"","dense":"","outlined":"","type":"date"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Hasta","hide-details":"auto","required":"","dense":"","outlined":"","type":"date"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.get_report}},[_vm._v("Buscar")])],1)],1)]},proxy:true},{key:"item.categoria",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.categoria + " / " + item.grupo))])]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.brand + "-" + item.refe + "-" + item.color_name + "-" + item.talla))])]}},{key:"item.costo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("$" + parseFloat(item.costo).toLocaleString(2)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("$" + parseFloat(item.price).toLocaleString(2)))])]}},{key:"item.tcost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("$" + parseFloat(item.tcost).toLocaleString(2)))])]}},{key:"item.tprice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("$" + parseFloat(item.tprice).toLocaleString(2)))])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-right "},[_vm._v(_vm._s(_vm.sumTable('items', 'quantity')))]),_c('th'),_c('th',{staticClass:"text-right "},[_vm._v(_vm._s("$" + _vm.sumTable('items', 'tcost')))]),_c('th'),_c('th',{staticClass:"text-right "},[_vm._v(_vm._s("$" + _vm.sumTable('items', 'tprice')))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }